import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button, Space, IconUser, IconLogout } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Space>\n  <Button>로그인</Button>\n  <Button type=\"default\">로그인</Button>\n  <Button type=\"primary\">로그인</Button>\n  <Button type=\"dashed\">로그인</Button>\n  <Button type=\"text\">로그인</Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button mdxType="Button">로그인</Button>
    <Button type='default' mdxType="Button">로그인</Button>
    <Button type='primary' mdxType="Button">로그인</Button>
    <Button type='dashed' mdxType="Button">로그인</Button>
    <Button type='text' mdxType="Button">로그인</Button>
  </Space>
    </Playground>
    <h2 {...{
      "id": "different-sizes"
    }}>{`Different sizes`}</h2>
    <Playground __position={2} __code={'<Space>\n  <Button size=\"small\">로그인</Button>\n  <Button size=\"middle\">로그인</Button>\n  <Button size=\"large\">로그인</Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button size="small" mdxType="Button">로그인</Button>
    <Button size="middle" mdxType="Button">로그인</Button>
    <Button size="large" mdxType="Button">로그인</Button>
  </Space>
    </Playground>
    <h2 {...{
      "id": "different-rounds"
    }}>{`Different rounds`}</h2>
    <Playground __position={3} __code={'<Space>\n  <Button round={false}>로그인</Button>\n  <Button round={true}>로그인</Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button round={false} mdxType="Button">로그인</Button>
    <Button round={true} mdxType="Button">로그인</Button>
  </Space>
    </Playground>
    <h2 {...{
      "id": "ghost-buttons"
    }}>{`Ghost Buttons`}</h2>
    <p><inlineCode parentName="p">{`ghost`}</inlineCode>{` property will make button's background transparent, it is commonly used in colored background.`}</p>
    <Playground __position={4} __code={'<Space>\n  <Button ghost>로그인</Button>\n  <Button ghost type=\"primary\">\n    로그인\n  </Button>\n  <Button ghost type=\"dashed\">\n    로그인\n  </Button>\n  <Button ghost type=\"text\">\n    로그인\n  </Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button ghost mdxType="Button">로그인</Button>
    <Button ghost type='primary' mdxType="Button">로그인</Button>
    <Button ghost type='dashed' mdxType="Button">로그인</Button>
    <Button ghost type='text' mdxType="Button">로그인</Button>
  </Space>
    </Playground>
    <h2 {...{
      "id": "danger-buttons"
    }}>{`Danger Buttons`}</h2>
    <Playground __position={5} __code={'<Space>\n  <Button danger>삭제</Button>\n  <Button ghost danger>\n    삭제\n  </Button>\n  <Button danger type=\"primary\">\n    삭제\n  </Button>\n  <Button danger type=\"dashed\">\n    삭제\n  </Button>\n  <Button danger type=\"text\">\n    삭제\n  </Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button danger mdxType="Button">삭제</Button>
    <Button ghost danger mdxType="Button">삭제</Button>
    <Button danger type='primary' mdxType="Button">삭제</Button>
    <Button danger type='dashed' mdxType="Button">삭제</Button>
    <Button danger type='text' mdxType="Button">삭제</Button>
  </Space>
    </Playground>
    <h2 {...{
      "id": "with-icon"
    }}>{`With icon`}</h2>
    <Playground __position={6} __code={'<Space>\n  <Button>\n    <IconUser />\n    로그인\n  </Button>\n  <Button>\n    <IconLogout />\n    로그아웃\n  </Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <Space mdxType="Space">
   <Button mdxType="Button"><IconUser mdxType="IconUser" />로그인</Button>
   <Button mdxType="Button"><IconLogout mdxType="IconLogout" />로그아웃</Button>
   </Space>
    </Playground>
    <h2 {...{
      "id": "different-disabled"
    }}>{`Different disabled`}</h2>
    <Playground __position={7} __code={'<Space>\n  <Button disabled={false}>로그인</Button>\n  <Button disabled={true}>로그인</Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Space,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button disabled={false} mdxType="Button">로그인</Button>
    <Button disabled={true} mdxType="Button">로그인</Button>
  </Space>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      